import styled from 'styled-components'

export const MobileContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  display: flex;
  flex-direction: column;

  button {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    outline: none;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    text-decoration: none;
    appearance: none;
    padding: 25px;
    margin: 0;
    background: none;

    .ar-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
      height: 100%;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 1px;

      & > img {
        margin: 20px 0;
        width: 40px;
        animation: pulseSize 3s infinite alternate-reverse;
      }
    }

    .loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 16px);
  justify-content: center;

  color: #000000;

  font-family: 'futura-pt', sans-serif;
`

export const Content = styled.div`
  display: flex;
  height: 90%;
  justify-content: center;

  @media (max-width: 641px) {
    height: 100%;
  }

  .left {
    width: 230px;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (max-width: 641px) {
      display: none;
    }

    h1 {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
    }

    .top {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .bottom {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .open-ar {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-size: 18px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.28;
          letter-spacing: normal;
          text-align: center;
          color: #000000;
        }
      }
    }
  }
  .right {
    width: 67%;
    max-width: 90vh;

    @media (max-width: 641px) {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .model-viewer-container {
      height: 100% !important;
      background-image: linear-gradient(to bottom, #e9eaeb, #e4e5e7);
      background-color: #eee;
      --poster-color: #fff;

      button {
        position: fixed;
        top: 0;
        right: 0;
        margin: 20px;

        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;

        img {
          width: 32px;
        }
      }
    }
  }
`
