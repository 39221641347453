import React from 'react'
import { useMoralis } from 'react-moralis'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Container = styled.div`
  width: 180px;
  height: 30px;

  align-self: center;

  button {
    align-items: center;

    width: 100%;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    background-color: #061621;
    color: white;
    border: 1px;
    padding: 10px;
    border-radius: 5px;
    font-size: 24px;

    @media (max-width: 641px) {
      font-size: 16px;
    }
  }

  @media (max-width: 641px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  pre {
    word-break: break-word;
    white-space: pre-wrap;
  }
`

const AuthenticateButton: React.FC = () => {
  const { authenticate, logout, isAuthenticating, isAuthenticated, user } =
    useMoralis()
  const { t } = useTranslation()

  if (!isAuthenticated) {
    return (
      <Container>
        <button type="button" onClick={() => authenticate()}>
          {t('nft.connect')}
        </button>
      </Container>
    )
  }

  return (
    <Container>
      <button
        type="button"
        disabled={isAuthenticating}
        onClick={() => logout()}
      >
        {t('nft.disconnect')}
      </button>
      <pre>{user?.get('ethAddress')}</pre>
    </Container>
  )
}

export default AuthenticateButton
