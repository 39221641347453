import type {
  ModelViewerElement,
  ModelViewerProps,
} from '@r2u/react-ar-components'
import React, { useState, useEffect, createRef } from 'react'

import { useParams } from 'react-router-dom'

import './style.css'

import * as ARComponents from '@r2u/react-ar-components'

const getModelViewer = async () => {
  const { ModelViewer } = await ARComponents
  return ModelViewer
}

interface Params {
  glb?: string
}

type ModelViewerJSX = React.ForwardRefExoticComponent<
  ModelViewerProps & React.RefAttributes<ModelViewerElement>
>

let ModelViewer: ModelViewerJSX

const ModelViewerFull: React.FC = () => {
  const [glbUrl, setGlbUrl] = useState(null)

  const { glb } = useParams<Params>()

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    getModelViewer().then((component) => {
      ModelViewer = component
      setLoaded(true)
    })
  }, [])

  useEffect(() => {
    setGlbUrl(glb ? decodeURIComponent(glb) : null)
  }, [glb])

  return (
    <div className="container-full">
      {glbUrl ? (
        <div className="model-viewer-container">
          {loaded && (
            <ModelViewer
              ref={createRef()}
              src={glbUrl}
              exposure={0.8}
              shadowSoftness={1}
              autoRotate={false}
              environmentImage="neutral"
              touchAction="none"
            />
          )}
        </div>
      ) : null}
    </div>
  )
}

export default ModelViewerFull
