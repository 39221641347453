import React, { createRef, useCallback, useEffect, useState } from 'react'
import { StringParam, BooleanParam, useQueryParams } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import { BrowserView, MobileView } from 'react-device-detect'
import QRCode from 'qrcode.react'

import type {
  ARProps,
  ModelViewerElement,
  ModelViewerProps,
} from '@r2u/react-ar-components'
import * as ARComponents from '@r2u/react-ar-components'
import '@r2u/javascript-ar-sdk'

import { dimensions, events } from '@r2u/analytics'
import { useMoralis } from 'react-moralis'
import UploadDropzone from './UploadDropzone'
import { Container, Content, MobileContent } from './styles'

import arIcon from '../../assets/images/ar-icon.png'
import undo from './undo.png'

import PoweredBy from '../../components/PoweredBy'
import Spinner from '../../components/Spinner'

import i18n from '../../config/i18n'
import AuthenticateButton from '../../components/AuthenticateButton'
import * as nft from '../../services/nft'

const { R2U } = window

const isIOS = (): boolean => /iPhone|iPad|iPod/i.test(navigator.userAgent)
const isAndroid = (): boolean =>
  /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

type Placement = 'floor' | 'wall'
type ShowInstructions = 'once' | 'always' | 'never'

interface ConfigureProps {
  customerId: string
  sku: string
  setName: (name: string) => void
  setGlb: (glb: string) => void
}

interface AttachProps {
  sku: string
  button: HTMLElement
  showInstructions: ShowInstructions
}

type ARFunction = (props: ARProps) => void
type ModelViewerJSX = React.ForwardRefExoticComponent<
  ModelViewerProps & React.RefAttributes<ModelViewerElement>
>

const configureSDK = async ({
  customerId,
  sku,
  setName,
  setGlb,
}: ConfigureProps) => {
  const { language } = i18n

  await R2U.init({ customerId, language: language as 'pt' | 'en' | 'es' })

  await R2U.sku.isActive(sku)
  const { name, glb } = await R2U.sku.getData(sku)

  setName(name)
  setGlb(glb)
}

const attachButton = async ({ sku, button, showInstructions }: AttachProps) => {
  await R2U.ar.attach({
    sku,
    element: button,
    eventType: 'click',
    fallbackOptions: { fallback: 'viewer' },
    showInstructions,
  })
}

let activateAR: ARFunction = () => undefined
let ModelViewer: ModelViewerJSX

const getModelViewer = async () => {
  const { ModelViewer: component } = await ARComponents
  ModelViewer = component
}

const getActivateAR = async () => {
  const { activateAR: arFunction } = await ARComponents
  activateAR = arFunction
}

const ProductPage: React.FC = () => {
  const { t, ready } = useTranslation()

  const { isAuthenticated, user, Moralis } = useMoralis()

  const [query, setQuery] = useQueryParams({
    glb: StringParam,
    usdz: StringParam,
    name: StringParam,
    autoRotate: BooleanParam,
    customerId: StringParam,
    sku: StringParam,
    placement: StringParam,
    resize: BooleanParam,
    autoOpen: BooleanParam,
    showInstructions: StringParam,
  })

  const [hasUploaded, setHasUploaded] = useState(false)

  const [glb, setGlb] = useState(query.glb)
  const [usdz] = useState(query.usdz)
  const [name, setName] = useState(query.name)
  const [placement = 'floor'] = useState(query.placement as Placement)
  const [resize = false] = useState(query.resize)
  const [autoRotate] = useState(query.autoRotate)
  const [autoOpen = false] = useState(query.autoOpen)
  const [showInstructions = 'always'] = useState(
    query.showInstructions as ShowInstructions
  )

  const scale = resize ? 'resizable' : 'fixed'

  useEffect(() => {
    if (ready && !name) {
      setName(t('productPage.name'))
    }
  }, [ready])

  const [customerId, setCustomerId] = useState(query.customerId)
  const [sku, setSku] = useState(query.sku)
  const sdkMode = customerId && sku

  const [initializedSDK, setInitializedSDK] = useState(false)
  const [loadingAR, setLoadingAR] = useState(true)
  const [fallback, setFallback] = useState(false)
  const [loadedARComponents, setLoadedARComponents] = useState(false)

  useEffect(() => {
    if (sdkMode) {
      configureSDK({
        customerId,
        sku,
        setName,
        setGlb,
      }).then(() => setInitializedSDK(true))
    }
  }, [sdkMode])

  useEffect(() => {
    getModelViewer()
      .then(getActivateAR)
      .then(() => setLoadedARComponents(true))
  }, [])

  useEffect(() => {
    if (!customerId && !sku) {
      ;(async () => {
        if (isAuthenticated && user) {
          const options = {
            chain: nft.chain,
            address: user.get('ethAddress'),
          }
          const nfts = await Moralis.Web3API.account.getNFTs(options)
          const token = nfts.result.find(({ metadata }) => {
            const data = JSON.parse(metadata)
            return data.external_url?.includes('viewer.r2u.io')
          })
          const metadata = JSON.parse(token.metadata)
          const params = new URLSearchParams(
            new URL(metadata.external_url).search
          )
          setQuery({
            customerId: params.get('customerId'),
            sku: params.get('sku'),
          })
          setCustomerId(params.get('customerId'))
          setSku(params.get('sku'))
        }
      })()
    }
  }, [isAuthenticated, user, customerId, sku])

  const canOpenAR = (isAndroid() && glb) || (isIOS() && usdz)

  const warnARListener = () => {
    if (isAndroid() && !glb) {
      alert(t('productPage.alert.glb'))
    }
    if (isIOS() && !usdz) {
      alert(t('productPage.alert.usdz'))
    }
  }

  const activateARListener = () =>
    activateAR({ glb, usdz, placement, scale, mode: 'ar_preferred' })
  const fallbackListener = () => alert(t('productPage.alert.fallback'))
  const hashChangeListener = () => {
    if (window.location.hash === '#no-ar-fallback') {
      setFallback(true)
    }
  }

  const buttonRef = useCallback(
    (node: HTMLElement) => {
      if (node) {
        if (sdkMode) {
          // Wait for SDK initialization to attach AR to the button
          if (initializedSDK && loadingAR) {
            attachButton({ sku, button: node, showInstructions }).then(() =>
              setLoadingAR(false)
            )
          }

          // If attached, auto-click
          if ((initializedSDK && !loadingAR) || autoOpen) {
            node.click()
          }
        } else {
          // Wait for async loading of arComponents before adding listeners
          if (!loadedARComponents && loadingAR) {
            // Check if GLB is present on Android or USDZ on iOS
            if (canOpenAR) {
              node.addEventListener('click', activateARListener)
              window.addEventListener('hashchange', hashChangeListener)
              setLoadingAR(false)
            } else {
              // FIXME
              // node.addEventListener('click', warnARListener)
              // node.click()
              // setLoadingAR(false)
            }
          }

          // Check for a fallback hashChange, remove AR,
          // attach a listener to repeat fallback message and instantly call it
          if (fallback) {
            node.removeEventListener('click', activateARListener)
            node.addEventListener('click', fallbackListener)
            node.click()
          }
        }
      }
    },
    [initializedSDK, loadedARComponents, loadingAR, fallback, sdkMode]
  )

  const onClick = (): Promise<unknown> =>
    R2U.analytics.send({
      event: events.click,
      data: {
        [dimensions.placement]: 'ar_model_viewer',
      },
    })

  const componentDidMount = (): Promise<unknown> =>
    R2U.analytics.send({
      event: events.impression,
      data: {
        [dimensions.placement]: 'ar_model_viewer',
      },
      scope: 'page',
    })

  return (
    <>
      <MobileView>
        <MobileContent>
          <AuthenticateButton />
          <button ref={buttonRef} type="button" disabled={loadingAR}>
            {loadingAR ? (
              <div className="loading">
                <Spinner />
              </div>
            ) : (
              <div className="ar-info">
                <img src={arIcon} alt="" />
                <span>{t('button')}</span>
              </div>
            )}
            <PoweredBy />
          </button>
        </MobileContent>
      </MobileView>
      <BrowserView>
        <Container>
          {sdkMode && !initializedSDK ? (
            <div className="loading">
              <Spinner />
            </div>
          ) : (
            <Content>
              <div className="left">
                <div className="top">
                  <AuthenticateButton />
                  <h1>{name}</h1>
                </div>
                <div className="bottom">
                  <div className="open-ar">
                    <h2>{t('productPage.info')}</h2>
                    <QRCode value={window.location.href} />
                  </div>
                  <PoweredBy />
                </div>
              </div>
              <div className="right">
                {glb ? (
                  loadedARComponents && (
                    <div className="model-viewer-container">
                      <ModelViewer
                        ref={createRef()}
                        src={glb}
                        exposure={0.8}
                        shadowSoftness={1}
                        autoRotate={autoRotate}
                        environmentImage="neutral"
                        {...(sdkMode ? { onClick, componentDidMount } : {})}
                      >
                        {hasUploaded && (
                          <button
                            type="button"
                            onClick={() => {
                              setQuery({ glb: '' })
                              setGlb('')
                            }}
                          >
                            <img alt="undo" src={undo} />
                          </button>
                        )}
                      </ModelViewer>
                    </div>
                  )
                ) : (
                  <UploadDropzone
                    onComplete={(glbUrl) => {
                      setQuery({ glb: glbUrl })
                      setGlb(glbUrl)
                      setHasUploaded(true)
                    }}
                  />
                )}
              </div>
            </Content>
          )}
        </Container>
      </BrowserView>
    </>
  )
}

export default ProductPage
