import React, { Suspense } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { QueryParamProvider } from 'use-query-params'
import { I18nextProvider } from 'react-i18next'
import { MoralisProvider } from 'react-moralis'
import ModelViewerFull from './pages/ModelViewerFull'
import ProductPage from './pages/ProductPage'
import OpenAR from './pages/OpenAR'
import ConvertPage from './pages/Convert'
import Spinner from './components/Spinner'

import i18n from './config/i18n'
import moralis from './config/moralis'

const App: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <MoralisProvider appId={moralis.appId} serverUrl={moralis.serverUrl}>
        <Suspense fallback={<Spinner />}>
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              <Switch>
                <Route
                  path="/openar/:customerId/:productSku"
                  component={OpenAR}
                />
                <Route path="/3d/:glb" component={ModelViewerFull} />
                <Route path="/convert" component={ConvertPage} />
                <Route path="/" component={ProductPage} />
              </Switch>
            </QueryParamProvider>
          </Router>
        </Suspense>
      </MoralisProvider>
    </I18nextProvider>
  )
}

export default App
